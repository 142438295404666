import React, { useEffect, useState, useRef, useMemo } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  Spinner,
} from "reactstrap"
import Dropzone from "react-dropzone"
import Select from "react-select"
import * as Yup from "yup"
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import { getItems, postItem } from "../../store/products/item/actions"

import { getItemNotes as onGetItemNotes } from "../../store/products/itemNotes/actions"

import { getItemIngrediants as onGetItemIngrediants } from "../../store/products/ingrediants/actions"

import { getPrepareArea as onGetPrepareArea } from "../../store/products/prepareArea/actions"

import { getCategories as onGetCategories } from "../../store/products/category/actions"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"

import classes from "./index.module.scss"
import PrepareAreaModal from "./modals/PrepareAreaModal"
import IngrediantModal from "./modals/IngrediantModal"
import NotesModal from "./modals/NotesModal"
import MainCategoryModal from "./modals/MainCategoryModal"
import SubCategoryModal from "./modals/SubCategoryModal"
import SelectInputErrorMessage from "./SelectInputErrorMessage"
import ProductList from "./ProductList"
import useSelectInputErrMsg from "./hooks/use-selectInputErrMsg"
import { selectInputBgColors, layoutModeTypes } from "constants/layout"
import { getPageAction } from "store/actions"

import { privilegePageId, pageActionId } from "common/privilege"

const Products = props => {
  //meta title
  document.title = "Products List | Kalapata - Admin"
  const { errorMsgs, sets, states } = useSelectInputErrMsg({ t: props.t })
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)

  const [isEdit, setIsEdit] = useState(false)

  const [item, setItem] = useState([])
  const [itemId, setItemId] = useState("")

  const [itemName, setItemName] = useState("")

  const [itemNameLang2, setItemNameLang2] = useState("")

  const [price, setPrice] = useState(0)

  const [itemNotesList, setItemNotesList] = useState([])

  const [itemIngrediantsList, setItemIngrediantsList] = useState([])

  const [prepareAreaList, setPrepareAreaList] = useState([])

  const [selectedPrepareArea, setSelectedPrepareArea] = useState("")

  const [allCategoryList, setAllCategoryList] = useState([])

  const [mainCategoryList, setMainCategoryList] = useState([])

  const [selectedMainCat, setSelectedMainCat] = useState("")

  const [subCategoryList, setSubCategoryList] = useState([])

  const [selectedSubCat, setSelectedSubCat] = useState("")

  const [isSubmitDisable, setIsSubmitDisable] = useState(false)

  const [checkToggle, setCheckToggle] = useState(false)

  const [pageActionDataList, setPageActionDataList] = useState([])

  const itemIdAdded = useSelector(state => state.items.itemIdAdded)

  const { layoutModeType } = useSelector(state => state.Layout)

  const mainPrivillageData = useSelector(
    state => state.privillage.mainPrivillageData
  )
  const pageActionData = useSelector(state => state.privillage.pageActionData)

  const location = useLocation()

  // to disable save btn until data posted successfully
  useEffect(() => {
    if (itemIdAdded > 0) {
      setIsSubmitDisable(false)
      validation.resetForm()
      // reset select states
      sets.setShowMainCategoryErrMsg(false)
      sets.setShowSubCategoryErrMsg(false)
      sets.setShowPrepareAreaErrMsg(false)
      if (checkToggle) toggle()
      setCheckToggle(true)
    }
  }, [itemIdAdded])

  useEffect(() => {
    dispatch(
      getPageAction({
        empId: JSON.parse(localStorage.getItem("authUser")).pkEmpId,
        pageId: privilegePageId.products,
      })
    )
  }, [mainPrivillageData])

  useEffect(() => {
    setPageActionDataList(pageActionData)
  }, [pageActionData])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: itemId || "",
      itemName: itemName || "",
      // secondName: (updatedItem && updatedItem.itemNameLang2) || "",
      itemNameLang2: itemNameLang2 || "",
      notes: itemNotesList,
      ingrediants: itemIngrediantsList,
      price: price || "",
      prepareArea: selectedPrepareArea || "",
      mainCategoryId: selectedMainCat.pkCategory || "",
      mainCategoryName: selectedMainCat.categoryName || "",
      subCategoryId: Number(selectedSubCat.pkCategory) || "",
      subCategoryName: selectedSubCat.categoryName || "",
      subCategoryList: subCategoryList || [],
    },
    validationSchema: Yup.object({
      itemName: Yup.string().required(props.t("Please Enter Your Name")),
      itemNameLang2: Yup.string().required(
        props.t("Please Enter second language name")
      ),
      price: Yup.number().required(props.t("Please Enter Price")),
      mainCategoryId: Yup.number().required(
        props.t("Please select main category")
      ),
      subCategoryId: Yup.number().when("subCategoryList", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.number().required(props.t("Field is required")),
      }),
      prepareArea: Yup.object().required(props.t("Please select prepare area")),
    }),
    onSubmit: values => {
      let handleIngrediands = []
      itemIngrediantsList.length > 0 &&
        itemIngrediantsList.map(e =>
          handleIngrediands.push({
            fkMaterialId: e.FK_MaterialID,
            qty: e.Qty,
            // id: 0,
            // fkItemId: 0,
          })
        )
      let itemNotes = [...itemNotesList]
      itemNotes.map(note => {
        // note.fkItemId = 0;
        delete note.fkItemId
        delete note.id
        return note
      })
      const newItem = {
        item: {
          pkItemId: isEdit ? itemId : 0,
          itemName: values["itemName"],
          itemNameLang2: values["itemNameLang2"],
          price: Number(values["price"]),
          fkCategoryId:
            subCategoryList.length === 0
              ? Number(values["mainCategoryId"])
              : Number(values["subCategoryId"]),
          fkPrepareId: values["prepareArea"].pkPrepareId,
          foodstuffsOrSupplies: true,
          active: true,
        },
        itemIngrediants: handleIngrediands,
        // saveItemIngrediants: handleIngrediands,
        itemNotes: itemNotes,
      }
      // save new user
      const formData = new FormData()
      // Update the formData object
      selectedFiles.length > 0 && formData.append("file", selectedFiles[0])

      dispatch(postItem(newItem, formData))
      setCheckToggle(true)
      setIsSubmitDisable(true)
    },
  })

  // reset select states in first render
  useEffect(() => {
    sets.setShowMainCategoryErrMsg(false)
    sets.setShowSubCategoryErrMsg(false)
    sets.setShowPrepareAreaErrMsg(false)
  }, [])

  const items = useSelector(state => state.items.items)
  const loadingItems = useSelector(state => state.items.loadingItems)

  const itemNotes = useSelector(state => state.itemNotes.itemNotes)

  const itemIngrediants = useSelector(
    state => state.itemIngrediant.itemIngrediants
  )

  const allPrepareAreas = useSelector(
    state => state.prepareAreas.allPrepareAreas
  )

  const allCategories = useSelector(state => state.categories.allCategories)

  const newCategoryAdded = useSelector(
    state => state.categories.newCategoryAdded
  )

  const prepareAreaAdded = useSelector(
    state => state.prepareAreas.prepareAreaAdded
  )

  const handleNotesList = newList => {
    setItemNotesList(newList)
  }
  const handleIngrediantsList = newList => {
    setItemIngrediantsList(newList)
  }

  // Get Items
  useEffect(() => {
    dispatch(getItems())
    setIsEdit(false)
  }, [])

  useEffect(() => {
    if (items) {
      setItem(items)
      setIsEdit(false)
    }
  }, [items])

  // get prepare Area onGetPrepareArea

  useEffect(() => {
    if (allPrepareAreas && !allPrepareAreas.length) {
      dispatch(onGetPrepareArea())
      setIsEdit(false)
    }
  }, [dispatch, allPrepareAreas])

  useEffect(() => {
    if (allPrepareAreas) {
      setPrepareAreaList(allPrepareAreas)
      setIsEdit(false)
    }
  }, [allPrepareAreas])

  // get prepare area name
  useEffect(() => {
    let itemListCopy = []
    itemListCopy = [...items]
    for (let i = 0; i < itemListCopy.length; i++) {
      for (let j = 0; j < allPrepareAreas.length; j++) {
        if (itemListCopy[i].fkPrepareId) {
          if (itemListCopy[i].fkPrepareId === allPrepareAreas[j].pkPrepareId) {
            itemListCopy[i].prepareAreaName = allPrepareAreas[j].areaName
            break
          }
        }
      }
    }
    setItem(itemListCopy)
  }, [items, allPrepareAreas])

  // get catgories

  useEffect(() => {
    if (allCategories && !allCategories.length) {
      dispatch(onGetCategories())
      setIsEdit(false)
    }
  }, [dispatch, allCategories, newCategoryAdded])
  const handleSubCatAdded = () => {
    dispatch(onGetCategories())
  }

  useEffect(() => {
    if (allCategories) {
      const demoMain = []
      allCategories.map(
        cat => !cat.hasOwnProperty("fkCategoryId") && demoMain.push(cat)
      )
      setMainCategoryList(demoMain)
      setAllCategoryList(allCategories)
      setIsEdit(false)
    }
  }, [allCategories, newCategoryAdded])

  // get main category name for items table and set all category details to item
  useEffect(() => {
    let itemListCopy = []
    itemListCopy = [...items]
    for (let i = 0; i < itemListCopy.length; i++) {
      for (let j = 0; j < allCategories.length; j++) {
        if (itemListCopy[i].fkCategoryId) {
          // check if it main category
          if (!allCategories[j].hasOwnProperty("fkCategoryId")) {
            const subCatList = handleSubCat(allCategories[j].pkCategory, true)
            itemListCopy[i].subCatListToSet = subCatList
            if (itemListCopy[i].fkCategoryId === allCategories[j].pkCategory) {
              itemListCopy[i].mainCategoryName = allCategories[j].categoryName
              itemListCopy[i].mainCategoryId = allCategories[j].pkCategory
              break
            }
          } else {
            // check if it Sub category
            const subCatList = handleSubCat(allCategories[j].fkCategoryId, true)
            itemListCopy[i].subCatListToSet = subCatList
            if (itemListCopy[i].fkCategoryId === allCategories[j].pkCategory) {
              itemListCopy[i].mainCategoryName = allCategories[j].categoryName

              itemListCopy[i].mainCategoryId = allCategories[j].fkCategoryId
              itemListCopy[i].subCategoryId = allCategories[j].pkCategory
              itemListCopy[i].subCategoryName = allCategories[j].categoryName
              break
            }
          }
        }
      }
    }
    setItem(itemListCopy)
  }, [items, allCategories])

  // this useEffect is for updating new category in category list and set category and sub category values to new value added
  const [isSubUpdated, setIsSubUpdated] = useState(false)
  const [isMainUpdated, setIsMainUpdated] = useState(false)

  useEffect(() => {
    if (Object.keys(prepareAreaAdded).length > 0) {
      setSelectedPrepareArea(prepareAreaAdded)
    }
  }, [prepareAreaAdded])

  useEffect(() => {
    if (Object.keys(newCategoryAdded).length > 0) {
      // To check if new category is added
      if (newCategoryAdded.hasOwnProperty("fkCategoryId")) {
        // To check if it is sub category added
        getItemMainCat(newCategoryAdded.fkCategoryId) // To set main category value for sub category added after save
        // handleSubCat(newCategoryAdded.fkCategoryId);
        setIsSubUpdated(true)
      } else {
        // To check if it is main category added
        setIsMainUpdated(true) // state to force set main category value to new category added
      }
    }
  }, [newCategoryAdded])

  useEffect(() => {
    // if Sub category updated
    if (isSubUpdated) {
      let demoSub = []
      // map to get all sub category list sibbling to sub added and set to subCategoryList
      handleSubCat(newCategoryAdded.fkCategoryId)

      // to set sub category value selected
      setSelectedSubCat({
        categoryName: newCategoryAdded.categoryName,
        fkCategoryId: newCategoryAdded.fkCategoryId,
        pkCategory: newCategoryAdded.pkCategory,
      })
    }
    setIsSubUpdated(false)
  }, [isSubUpdated])

  useEffect(() => {
    // useEffect if added new main category to set value to new category added
    if (isMainUpdated) {
      if (mainCategoryList.length > 0) {
        for (let i = 0; i < mainCategoryList.length; i++) {
          if (
            Number(mainCategoryList[i].pkCategory) ===
            newCategoryAdded.pkCategory
          ) {
            setSelectedMainCat(mainCategoryList[i])
            break
          }
        }
      }
    }
    setIsMainUpdated(false)
  }, [isMainUpdated])

  const toggle = () => {
    setModal(!modal)
    setIsSubmitDisable(false)
  }

  const getItemNotes = itemId => {
    dispatch(onGetItemNotes(Number(itemId)))
  }

  const getItemIngrediants = itemId => {
    dispatch(onGetItemIngrediants(Number(itemId)))
  }

  useEffect(() => {
    if (itemNotes) {
      setItemNotesList(itemNotes)
    }
  }, [itemNotes])

  useEffect(() => {
    if (itemIngrediants) {
      setItemIngrediantsList(itemIngrediants)
    }
  }, [itemIngrediants])

  const getItemMainCat = e => {
    if (mainCategoryList.length > 0) {
      for (let i = 0; i < mainCategoryList.length; i++) {
        if (Number(mainCategoryList[i].pkCategory) === Number(e)) {
          setSelectedMainCat(mainCategoryList[i])
          break
        }
      }
    }
    // setSelectedMainCat(demoEditcat)
  }

  const handleEditItem = arg => {
    setCheckToggle(true)

    const item = arg
    resetItemModal()
    setItemId(item.pkItemId)
    setItemName(item.itemName)
    setItemNameLang2(item.itemNameLang2)
    setPrice(item.price)
    getItemNotes(item.pkItemId)
    getItemIngrediants(item.pkItemId)

    getItemMainCat(item.fkCategoryId)
    setIsEdit(true)
    setSelectedPrepareArea({
      pkPrepareId: item.fkPrepareId,
      areaName: item.prepareAreaName,
    })
    if (item.hasOwnProperty("subCategoryId")) {
      handleSubCat(item.mainCategoryId)

      setSelectedMainCat({
        categoryName: item.mainCategoryName,
        pkCategory: item.mainCategoryId,
      })
      setSubCategoryList(item.subCatListToSet)
      setSelectedSubCat({
        categoryName: item.subCategoryName,
        fkCategoryId: item.mainCategoryId,
        pkCategory: item.subCategoryId,
      })
    } else {
      if (item.hasOwnProperty("subCatListToSet")) {
        setSubCategoryList(item.subCatListToSet)
      }
      setSelectedMainCat({
        categoryName: item.mainCategoryName,
        pkCategory: item.mainCategoryId,
      })
    }
    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  // const [deleteModal, setDeleteModal] = useState(false)

  // const onClickDelete = items => {
  //   // setItem(items)
  //   setDeleteModal(true)
  // }

  // const handleDeleteUser = () => {
  //   if (item && item.id) {
  //     // dispatch(onDeleteUser(item.id))
  //   }
  //   onPaginationPageChange(1)
  //   setDeleteModal(false)
  // }

  function handleSelectPrepareArea(e) {
    setSelectedPrepareArea(e)
  }

  function handleSelectMainCat(e) {
    setSelectedMainCat(e)
    handleSubCat(e.pkCategory)
    setSelectedSubCat("")
  }

  const handleSubCat = (pkCat, isHandleCat = false) => {
    setSubCategoryList([])
    let demoSubCatList = []
    allCategoryList.map(
      mainCat =>
        Number(mainCat.fkCategoryId) === Number(pkCat) &&
        demoSubCatList.push({
          pkCategory: mainCat.pkCategory,
          categoryName: mainCat.categoryName,
          fkCategoryId: mainCat.fkCategoryId,
        })
    )
    if (isHandleCat) return demoSubCatList
    setSubCategoryList(demoSubCatList)
  }

  function handleSelectedSubCat(e) {
    setSelectedSubCat(e)
    // validation.setFieldValue("mainCategoryId", e);
  }
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const keyField = "id"

  // PrepareAreaModel Added by Asmaa
  const [prepareAreaModal, setPrepareAreaModal] = useState(false)

  const handlePrepareAreaModal = () => {
    setPrepareAreaModal(true)
  }

  const handlePrepareAreaToggle = () => {
    setPrepareAreaModal(!prepareAreaModal)
  }

  const [ingrediantModal, setIngrediantModal] = useState(false)

  const handleIngrediantModal = () => {
    setIngrediantModal(true)
  }

  const handleIngrediantToggle = () => {
    setIngrediantModal(!ingrediantModal)
  }

  const [noteModal, setNoteModal] = useState(false)

  const handleNoteModal = () => {
    setNoteModal(true)
  }

  const handleNotesToggle = () => {
    setNoteModal(!noteModal)
  }

  const [mainCategoryModal, setMainCategoryModal] = useState(false)

  const handleMainCategoryModal = () => {
    setMainCategoryModal(true)
  }

  const handleMainCategoryToggle = () => {
    setMainCategoryModal(!mainCategoryModal)
  }

  const [subCategoryModal, setSubCategoryModal] = useState(false)

  const handleSubCategoryModal = () => {
    setSubCategoryModal(true)
  }

  const handleSubCategoryToggle = () => {
    setSubCategoryModal(!subCategoryModal)
  }

  const handleCreateNewItem = () => {
    resetItemModal()
    // setUpdatedItem("")
    toggle()
  }

  const resetItemModal = () => {
    setIsEdit(false)
    setItemName("")
    setItemNameLang2("")
    setSelectedMainCat("")
    setSelectedSubCat("")
    setSelectedPrepareArea("")
    setPrice(0)
    setItemNotesList([])
    setItemIngrediantsList([])
    setselectedFiles([])
  }

  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        textElement='Item'
      /> */}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("Products")}
            breadcrumbItem={props.t("Products List")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ProductList
                    item={item}
                    handleCreateNewItem={handleCreateNewItem}
                    handleEditItem={handleEditItem}
                    pageActionData={pageActionDataList}
                  />

                  <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? props.t("Edit Product")
                        : props.t("Add Product")}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          // // if select field are empty make sure to show error msg
                          sets.setShowMainCategoryErrMsg(true)
                          subCategoryList.length > 0 &&
                            sets.setShowSubCategoryErrMsg(true)
                          sets.setShowPrepareAreaErrMsg(true)

                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Item Name “First Lang”")}
                                  </Label>
                                  <Input
                                    name="itemName"
                                    type="text"
                                    placeholder={props.t("First Lang Name")}
                                    onChange={e => setItemName(e.target.value)}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.itemName || ""}
                                    invalid={
                                      validation.touched.itemName &&
                                      validation.errors.itemName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.itemName &&
                                  validation.errors.itemName ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.itemName}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Item Name “Second Lang”")}
                                  </Label>
                                  <Input
                                    name="itemNameLang2"
                                    type="text"
                                    placeholder={props.t("second Lang Name")}
                                    onChange={e =>
                                      setItemNameLang2(e.target.value)
                                    }
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.itemNameLang2 || ""
                                    }
                                    invalid={
                                      validation.touched.itemNameLang2 &&
                                      validation.errors.itemNameLang2
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.itemNameLang2 &&
                                  validation.errors.itemNameLang2 ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.itemNameLang2}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label>{props.t("Main Category")}</Label>
                                  <div className="input-group">
                                    <Select
                                      value={
                                        selectedMainCat !== ""
                                          ? mainCategoryList.find(function (
                                              option
                                            ) {
                                              return selectedMainCat
                                                ? option.pkCategory ===
                                                    Number(
                                                      selectedMainCat.pkCategory
                                                    )
                                                : Number(
                                                    validation.values
                                                      .mainCategoryId
                                                  )
                                            })
                                          : ""
                                      }
                                      onChange={e => {
                                        handleSelectMainCat(e)
                                      }}
                                      options={mainCategoryList}
                                      className={`select2-selection form-control 
                                      ${
                                        states.showMainCategoryErrMsg &&
                                        !selectedMainCat &&
                                        classes.red_border
                                      } 
                                      ${classes.input_with_btn}`}
                                      getOptionLabel={mainCategoryList =>
                                        mainCategoryList["categoryName"]
                                      }
                                      getOptionValue={mainCategoryList =>
                                        mainCategoryList["pkCategory"]
                                      }
                                      onFocus={() =>
                                        sets.setShowMainCategoryErrMsg(false)
                                      }
                                      onBlur={() =>
                                        sets.setShowMainCategoryErrMsg(true)
                                      }
                                      styles={{
                                        menu: base => ({
                                          ...base,
                                          backgroundColor:
                                            layoutModeType ===
                                            layoutModeTypes.LIGHT
                                              ? selectInputBgColors.light
                                              : selectInputBgColors.dark,
                                        }),
                                      }}
                                    />
                                    {pageActionDataList.some(
                                      action =>
                                        action.PK_PageAction_ID ===
                                        pageActionId.products.addCategory
                                    ) && (
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        id="inputGroupFileAddon04"
                                        onClick={handleMainCategoryModal}
                                      >
                                        {props.t("Add")}
                                      </button>
                                    )}
                                  </div>
                                  {states.showMainCategoryErrMsg &&
                                    !selectedMainCat && (
                                      <SelectInputErrorMessage>
                                        {errorMsgs.mainCategory}
                                      </SelectInputErrorMessage>
                                    )}
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label>{props.t("Sub Category")}</Label>
                                  <div className="input-group">
                                    <Select
                                      value={
                                        selectedSubCat !== ""
                                          ? subCategoryList.find(function (
                                              option
                                            ) {
                                              return (
                                                option.pkCategory ===
                                                Number(
                                                  selectedSubCat.pkCategory
                                                )
                                              )
                                            })
                                          : ""
                                      }
                                      onChange={e => {
                                        handleSelectedSubCat(e)
                                      }}
                                      options={subCategoryList}
                                      getOptionLabel={subCategoryList =>
                                        subCategoryList["categoryName"]
                                      }
                                      getOptionValue={subCategoryList =>
                                        subCategoryList["pkCategory"]
                                      }
                                      className={`select2-selection form-control 
                                      ${
                                        states.showSubCategoryErrMsg &&
                                        !selectedSubCat &&
                                        classes.red_border
                                      } 
                                      ${classes.input_with_btn}`}
                                      onFocus={() =>
                                        sets.setShowSubCategoryErrMsg(false)
                                      }
                                      onBlur={() =>
                                        subCategoryList.length > 0 &&
                                        sets.setShowSubCategoryErrMsg(true)
                                      }
                                      styles={{
                                        menu: base => ({
                                          ...base,
                                          backgroundColor:
                                            layoutModeType ===
                                            layoutModeTypes.LIGHT
                                              ? selectInputBgColors.light
                                              : selectInputBgColors.dark,
                                        }),
                                      }}
                                    />
                                    {pageActionDataList.some(
                                      action =>
                                        action.PK_PageAction_ID ===
                                        pageActionId.products.addSubCategory
                                    ) && <button
                                      className="btn btn-primary"
                                      type="button"
                                      id="inputGroupFileAddon04"
                                      onClick={handleSubCategoryModal}
                                    >
                                      {props.t("Add")}
                                    </button>}
                                  </div>
                                  {states.showSubCategoryErrMsg &&
                                    !selectedSubCat && (
                                      <SelectInputErrorMessage>
                                        {errorMsgs.subCategory}
                                      </SelectInputErrorMessage>
                                    )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label>{props.t("Prepare Area")}</Label>
                                  <div className="input-group">
                                    <Select
                                      value={
                                        selectedPrepareArea !== ""
                                          ? prepareAreaList.find(function (
                                              option
                                            ) {
                                              return (
                                                option.pkPrepareId ===
                                                Number(
                                                  selectedPrepareArea.pkPrepareId
                                                )
                                              )
                                            })
                                          : ""
                                      }
                                      onChange={e => {
                                        handleSelectPrepareArea(e)
                                      }}
                                      options={prepareAreaList}
                                      className={`select2-selection form-control 
                                      ${
                                        states.showPrepareAreaErrMsg &&
                                        !selectedPrepareArea &&
                                        classes.red_border
                                      } 
                                      ${classes.input_with_btn}`}
                                      getOptionLabel={prepareAreaList =>
                                        prepareAreaList["areaName"]
                                      }
                                      getOptionValue={prepareAreaList =>
                                        prepareAreaList["pkPrepareId"]
                                      }
                                      onFocus={() =>
                                        sets.setShowPrepareAreaErrMsg(false)
                                      }
                                      onBlur={() =>
                                        sets.setShowPrepareAreaErrMsg(true)
                                      }
                                      styles={{
                                        menu: base => ({
                                          ...base,
                                          backgroundColor:
                                            layoutModeType ===
                                            layoutModeTypes.LIGHT
                                              ? selectInputBgColors.light
                                              : selectInputBgColors.dark,
                                        }),
                                      }}
                                    />
                                    {pageActionDataList.some(
                                      action =>
                                        action.PK_PageAction_ID ===
                                        pageActionId.products.addPrepareArea
                                    ) && <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={handlePrepareAreaModal}
                                      id="inputGroupFileAddon04"
                                    >
                                      {props.t("Add")}
                                    </button>}
                                  </div>
                                  {states.showPrepareAreaErrMsg &&
                                    !selectedPrepareArea && (
                                      <SelectInputErrorMessage>
                                        {errorMsgs.prepareArea}
                                      </SelectInputErrorMessage>
                                    )}
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Price")}
                                  </Label>
                                  <Input
                                    name="price"
                                    type="number"
                                    min={0}
                                    placeholder={props.t("Enter Price")}
                                    onChange={e => setPrice(e.target.value)}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.price || ""}
                                    invalid={
                                      validation.touched.price &&
                                      validation.errors.price
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.price &&
                                  validation.errors.price ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.price}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Notes")}
                                  </Label>
                                  <Input
                                    disabled={true}
                                    type="select"
                                    name="notes"
                                    className="form-select"
                                    multiple={true}
                                    placeholder={props.t("Enter notes")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.notes || []}
                                    invalid={
                                      validation.touched.notes &&
                                      validation.errors.notes
                                        ? true
                                        : false
                                    }
                                  >
                                    {itemNotesList.map((note, index) => (
                                      <option key={index}>{note.note}</option>
                                    ))}
                                  </Input>
                                  {validation.touched.notes &&
                                  validation.errors.notes ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.notes}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="d-flex justify-content-end">
                                  <Button
                                    color="warning"
                                    outline
                                    className="justify-content-end"
                                    type="button"
                                    onClick={handleNoteModal}
                                  >
                                    {props.t("Add Notes")}
                                  </Button>
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {props.t("Ingredients")}
                                  </Label>
                                  <Input
                                    disabled={true}
                                    type="select"
                                    name="ingrediants"
                                    className="form-select"
                                    multiple={true}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.ingrediants || []}
                                    invalid={
                                      validation.touched.ingrediants &&
                                      validation.errors.ingrediants
                                        ? true
                                        : false
                                    }
                                  >
                                    {itemIngrediantsList.map(
                                      (ingred, index) => (
                                        <option key={index}>
                                          {ingred.MaterialName}
                                        </option>
                                      )
                                    )}
                                  </Input>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <Button
                                    color="warning"
                                    outline
                                    className="justify-content-end"
                                    onClick={handleIngrediantModal}
                                    type="button"
                                  >
                                    {props.t("Add Ingredients")}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            {/* Upload file */}
                            {/* <div className="mb-3">
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    className={`dropzone ${classes.dropzone_area}`}
                                  >
                                    <div
                                      className={`dz-message needsclick mt-2 ${classes.dz_message_area}`}
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>
                                        {props.t("Drop files here or click to upload")}
                                      </h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {selectedFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  );
                                })}
                              </div>
                            </div> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end mt-3">
                              {/* <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                {props.t("Save")}
                              </button> */}
                              <button
                                type="submit"
                                disabled={isSubmitDisable}
                                className="btn btn-success save-user"
                              >
                                {isSubmitDisable ? (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                ) : (
                                  <i className="mdi mdi-check-circle-outline me-1" />
                                )}
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                  <PrepareAreaModal
                    prepareAreaModal={prepareAreaModal}
                    handlePrepareAreaToggle={handlePrepareAreaToggle}
                  />
                  <IngrediantModal
                    ingrediantModal={ingrediantModal}
                    handleIngrediantToggle={handleIngrediantToggle}
                    handleIngrediantsList={handleIngrediantsList}
                    tableRows={itemIngrediantsList}
                    pageActionDataList={pageActionDataList}
                  />
                  <NotesModal
                    noteModal={noteModal}
                    handleNotesToggle={handleNotesToggle}
                    tableRows={itemNotesList}
                    handleNotesList={handleNotesList}
                  />
                  <MainCategoryModal
                    mainCatModal={mainCategoryModal}
                    handleMainCategoryToggle={handleMainCategoryToggle}
                  />
                  <SubCategoryModal
                    subCategoryModal={subCategoryModal}
                    handleSubCategoryToggle={handleSubCategoryToggle}
                    mainCatElement={mainCategoryList}
                    handleSubCatAdded={handleSubCatAdded}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Products.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Products))
