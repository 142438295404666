import PropTypes from "prop-types";
import React, { useEffect } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

import { toast } from "react-toastify";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin, loginUserData } from "../../store/actions";

// import images
import kalapata_logo from "assets/images/kalapata_logo.png";
import logo from "assets/images/logo.svg";

import classes from "./Login.module.scss";
import { useState } from "react";
import { privilegePageId } from "common/privilege"


const Login = props => {
  //meta title
  document.title = "Login | Kalapata";
  const [errorMsg, setErrorMsg] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [branchName, setBranchName] = useState("");
  const [mainPrivillage, setMainPrivillage] = useState([])

  const mainPrivillageData = useSelector(state => state.privillage.mainPrivillageData)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    mainPrivillageData && (mainPrivillageData.length && setMainPrivillage(mainPrivillageData))
  }, [mainPrivillageData])


  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin@themesbrand.com" || '',
      // password: "123456" || '',
      userName: userName || "",
      password: password || "",
      branchName: branchName || "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your userName"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      setTimeout(() => {
        dispatch(loginUserData(values, props.router.navigate));
      }, 1000)
    },
  });

  const error = useSelector(state => state.loginUser.error);
  const userData = useSelector(state => state.loginUser.userData);
  useEffect(() => {
    if (userData && mainPrivillage.length) {
      mainPrivillage.some( privilege =>  privilege.PK_Page_ID === privilegePageId.dashboard) ? navigate('/dashboard') : navigate('/products')
      
      // navigate('/dashboard');
    }
    
  }, [userData, mainPrivillage])

  // useEffect(() => {
  //   if (error === "unauthorized") {
  //     toast.dismiss()
  //     toast.error("Invalid username or password")
  //   } else if (error !== "") {
  //     toast.dismiss()
  //     toast.error("Something error please try again.")
  //   }
  // }, [error])

  const signIn = type => {
    setTimeout(() => {
      
      dispatch(socialLogin(type, props.router.navigate));
    }, 1000)
  };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    !userData ?
      <React.Fragment>
        <div className="account-pages pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className={`overflow-hidden ${classes.card_shadow}`}>
                  <div className="bg-primary bg-soft">
                    <Row className="m-0">
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to Kalapata.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-center">
                        <img src={kalapata_logo} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error !== "" ? (
                          error === "unauthorized" ? (
                            <Alert color="danger">
                              Invalid username or password.
                            </Alert>
                          ) : (
                            <Alert color="danger">
                              Something Error, please try again.
                            </Alert>
                          )
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">User Name</Label>
                          <Input
                            name="userName"
                            className="form-control"
                            placeholder="Enter UserName"
                            type="text"
                            onChange={e => setUserName(e.target.value)}
                            onBlur={validation.handleBlur}
                            value={userName || ""}
                            invalid={
                              validation.touched.userName && validation.errors.userName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.userName && validation.errors.userName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.userName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                                validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                            validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            value="moq"
                            onClick={e => {
                              e.preventDefault();
                              setBranchName(e.target.value);
                              localStorage.setItem("branchName", "moq");
                              validation.handleSubmit();
                              return false;
                            }}
                            type="button"
                          >
                            Login
                          </button>
                          
                          {/* <button
                            className="btn btn-primary btn-block mt-2"
                            type="button"
                            value="ezdan"
                            onClick={e => {
                              e.preventDefault();
                              setBranchName(e.target.value);
                              localStorage.setItem("branchName", "ezdan");
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            Login to Ezdan
                          </button> */}
                        </div>

                        {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("facebook");
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>
                          <li className="list-inline-item">
                          <TwitterLogin loginUrl=    "http://localhost:4000/api/v1/auth/twitter"
                           onSuccess={this.twitterResponse}
                           onFailure={this.onFailure}
                           requestTokenUrl=
                            "http://localhost:4000/api/v1/auth/twitter/revers"
                          
                            showIcon={false}
                             tag={"div"}
                            >
                             <a
                            href=""
                              className="social-list-item bg-info text-white border-info"
                             >
                             <i className="mdi mdi-twitter"/>
                            </a>
                           </TwitterLogin></li>
                          <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("google");
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                  <p>
                    © {new Date().getFullYear()} Kalapata, Crafted with{" "}
                    <i className="mdi mdi-heart text-danger" /> by SmartVillage
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      : <></>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
