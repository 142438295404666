import React from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import CounterSection from "pages/Dashboard/components/Counter"
import CustomerMap from "pages/Dashboard/components/customerMap/CustomerMap"
import ManageDashbaordLayout from "pages/Dashboard/components/ManageDashbaordLayout"
import OrderSummary from "pages/Dashboard/components/ordersSummary/OrderSummary"
import Revenue from "pages/Dashboard/components/revenue/Revenue"

import classes from "./index.module.scss"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | Kalapata - Admin"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <CounterSection />
          <div className={classes.chartsContainer}>
            <OrderSummary />
            <Revenue />
          </div>
          <Row>
            <Col lg="12" xxl="12">
              <CustomerMap />
            </Col>
            {/* <Col lg="6" xxl="3">
              <ManageDashbaordLayout />
            </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Dashboard))
